import { AddressProps } from '@rugby-au/form-fields';
import { MembershipProductProps } from '@rugby-au/membership';
import { BillData } from '@rugby-au/payments';
import { get, post } from '../../API';
import { CartProps, getCart, resetCart, setCart } from './cart';
import { getUser } from '../auth/user';

type MembershipCalculateResultProps = {
  cartId: string;
  cartExpiry: number;
  entityType: string;
  entityId: string;
  items: {
    _id: string;
    type: string;
    reserved: {
      id: string;
      qty: number;
      expiry: number;
    };
    quantity: number;
    itemTotal: number;
    itemPrice: number;
    title: string;
    period: string;
    members: {
      __productType: string;
      firstName: string;
      lastName: string;
      dob: string;
      email: string;
      phoneNumber: string;
      postcode: string;
      relationship: string;
      mySelfCheck: boolean;
    }[];
  }[];
  processingFee: number;
  processingPerc: number;
  total: number;
  subTotal: number;
};
type ErrorProps = { error: { message: string } };

export type CalculateMembershipReturnProps = {
  billDetail: {
    id: string;
    name: string;
    price: number;
    types: {
      id: string;
      name: string;
      qty: number;
      useSameDetails: boolean;
    }[];
  }[];
  processingFee: number;
  total: number;
  processingPerc: number;
  subTotal: number;
  cartMeta: { cartId: string; cartExpiry: number };
};

export const getProductById = async (id: string) => {
  try {
    const product: any = await get({ path: `rau/api/v1/memberships/product/${id}` });
    if (product.error) {
      //Custom error handling
      return null;
    }

    const currentTime = new Date().getTime();
    //check if membership is current
    if (product.openDate && product.closeDate && new Date(product.openDate).getTime() < currentTime && currentTime < new Date(product.closeDate).getTime()) {
      return product as MembershipProductProps;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};
export const getProductList = async ({
  entityType,
  entity,
  category,
  type,
  order = true,
}: {
  entityType: string;
  entity: string;
  category?: string;
  type?: string;
  order?: boolean;
}) => {
  let products: any = await get({ path: `rau/api/v1/memberships/products/${entityType}/${entity}` });
  if (products.error) {
    console.log('🚀 ~ file: membershipProduct.ts:98 ~ products:', products);
    //Custom error handling
    return [];
  }

  const currentTime = new Date().getTime();
  //filter to display current products
  products = products.filter(
    (product: MembershipProductProps) => product.active && new Date(product.openDate).getTime() < currentTime && currentTime < new Date(product.closeDate).getTime(),
  );
  //Make sure there's atleast one active type in each product
  products = products.filter((product: MembershipProductProps) => {
    return (product.types.filter(_type => _type.active).length > 0 && product.mode === 'Sale') || product.mode !== 'Sale' ? true : false;
  });
  if (type) {
    products = products.filter((product: MembershipProductProps) => product.type === type);
  }
  if (category) {
    products = products.filter((product: MembershipProductProps) => product.category === category);
  }
  if (order) {
    products = products.sort((a: MembershipProductProps, b: MembershipProductProps) => a.order - b.order);
  }
  return products as MembershipProductProps[];
};

export const calculateMembershipCart = async (cart: CartProps, entityId: string = 'RAU', entityType: string = 'national') => {
  try {
    const items = getCalculateItemsFromCart(cart, true);

    const calculateResult = (await post({
      params: {
        cart: { items, entityId, entityType, ...(cart.meta?.cartId ? { id: cart.meta.cartId } : {}) },
      },
      path: 'rau/api/v1/memberships/calculate',
    })) as MembershipCalculateResultProps | ErrorProps;

    if ((calculateResult as ErrorProps).error) {
      throw (calculateResult as ErrorProps).error;
    } else {
      calculateResult as MembershipCalculateResultProps;
    }
    //Group items by product
    const productMap = (calculateResult as MembershipCalculateResultProps).items.reduce((accu, item) => {
      const productInCart = cart.items.find(cartItem => cartItem.id === item._id);
      const cartItemType = productInCart?.types.find(type => type.id === item.type);

      accu[item._id] = accu[item._id] || {};
      accu[item._id].types = accu[item._id].types || [];
      accu[item._id].types.push({ id: item.type, name: item.type, qty: item.quantity, useSameDetails: cartItemType?.useSameDetails ?? false });
      accu[item._id].id = item._id;
      accu[item._id].name = productInCart?.name || '';
      accu[item._id].price = roundAmount(accu[item._id].price || 0);
      accu[item._id].price = roundAmount(accu[item._id].price + item.itemTotal);

      return accu;
    }, {} as { [key: string]: { id: string; name: string; price: number; types: { id: string; name: string; qty: number; useSameDetails: boolean }[] } });

    const cartExpiry = (calculateResult as MembershipCalculateResultProps).cartExpiry ?? cart.meta?.cartExpiry;
    await setCart({
      items: cart.items,
      meta: { cartId: (calculateResult as MembershipCalculateResultProps).cartId, cartExpiry: cartExpiry },
    });
    return {
      billDetail: Object.entries(productMap).map(([, value]) => {
        return value;
      }),
      processingFee: (calculateResult as MembershipCalculateResultProps).processingFee,
      total: roundAmount((calculateResult as MembershipCalculateResultProps).total),
      processingPerc: (calculateResult as MembershipCalculateResultProps).processingPerc,
      subTotal: roundAmount((calculateResult as MembershipCalculateResultProps).subTotal),
      cartMeta: { cartId: (calculateResult as MembershipCalculateResultProps).cartId, cartExpiry: cartExpiry },
    } as CalculateMembershipReturnProps;
  } catch (error) {
    throw error;
  }
};

export const checkOutNewMembership = async ({
  billData,
  cardData,
  entityType,
  entity = 'RAU',
}: {
  billData: BillData | null;
  cardData: any;
  entityType: string;
  entity: string;
}) => {
  try {
    const cart = await getCart();
    if (!cart?.items?.length || !cart?.meta?.cartId) {
      resetCart();
      throw Error('Cart is empty, Please add items to cart');
    }
    const currentTime = new Date().getTime();
    if (cart?.meta?.cartExpiry && currentTime > cart?.meta?.cartExpiry) {
      // TODO : confirm with UX process
      resetCart();
      throw Error('Cart Session expired, Please add items to cart again');
    }

    // Populate data object for the new membership API
    const data = getCalculateItemsFromBill(billData, cart);
    const response = await post({
      params: {
        card: cardData,
        cart: {
          entityType,
          entityId: entity,
          items: data,
          ...(cart.meta?.cartId ? { id: cart.meta.cartId, cartExpiry: cart.meta.cartExpiry } : {}),
        },
      },
      path: 'rau/api/v1/memberships/new',
      useUserToken: true,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.error) {
      throw Error(response.error.message);
    }
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Error while checking out new membership, Please try again, or contact support');
  }
};

const getCalculateItemsFromCart = (cart: CartProps, addMembers: boolean) => {
  try {
    const items = cart.items.reduce((acc, item) => {
      if (item.types) {
        item.types.forEach(type => {
          if (type.quantity > 0) {
            const members = getMembersForType(item.formData, type.id);
            acc.push({ _id: item.id, type: type.id, quantity: type.quantity, autoRenew: item.autoRenew ?? false, ...(addMembers ? { members: members } : {}) });
          }
        });
      }
      return acc;
    }, [] as { _id: string; type: string; quantity: number; autoRenew?: boolean }[]);
    return items;
  } catch (error) {
    console.log('utils.ts:15 ~ getCalculateItemsFromCart ~ error', error);
    return [];
  }
};

const getCalculateItemsFromBill = (bill: BillData | null, cart: CartProps) => {
  try {
    const items = bill?.billDetail.reduce((acc, item) => {
      if (item.items) {
        item.items.forEach(type => {
          if (type.qty > 0) {
            const cartFormData = cart.items.find(cartItem => cartItem.id === item.id)?.formData;
            const members = getMembersForType(cartFormData, type.id, type?.useSameDetails ?? false);
            acc.push({
              _id: item.id,
              type: type.id,
              quantity: type.qty,
              autoRenew: item.autoRenew,
              useSameDetails: type?.useSameDetails ?? false,
              members: members.map(memberItem => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { __productType, ...rest } = memberItem;
                return rest;
              }),
            });
          }
        });
      }
      return acc;
    }, [] as { _id: string; type: string; quantity: number; autoRenew: boolean; useSameDetails: boolean; members: { [key: string]: any }[] }[]);
    return items;
  } catch (error) {
    console.log('membershipProduct.ts:139 ~ getCalculateItemsFromBill ~ error', error);
    return [];
  }
};

const getMembersForType = (
  formData:
    | {
        [key: string]: any;
        __productType: string;
      }[]
    | undefined,
  type: string,
  useSameDetails: boolean = false,
) => {
  if (useSameDetails) {
    return formData && formData[0] ? [formData[0]] : [];
  } else {
    return formData?.filter(form => form.__productType === type) ?? [];
  }
};

export type MembershipProps = {
  id: string;
  member: {
    firstName: string;
    lastName: string;
    dob: string;
    email?: string;
    mySelfCheck?: boolean;
    phoneNumber?: string;
    address?: AddressProps;
  };
  title: string;
  type: string;
  price: number;
  pinChargeId: number;
  invoiceNumber: string;
  purchaseDate: string;
  expiry: number;
  autoRenew: boolean;
  active: boolean;
};
export type UserMembershipResponseProps = {
  _id: string;
  profile: any;
  products: MembershipProps[];
  error?: any;
};
export const getUserMemberships = async () => {
  try {
    const user = await getUser();
    if (user && user.userId) {
      const memberships: UserMembershipResponseProps = await get({ path: `rau/api/v1/memberships/myMemberships/${user.userId}`, useUserToken: true });
      // if (memberships.error) {
      //   //Custom error handling
      //   throw Error(memberships.error.message);
      // }
      return memberships;
    }
  } catch (error) {
    throw error;
  }
};
const roundAmount = (amount: number) => {
  return Math.round(amount * 100) / 100;
};
