import { cacheResponse, fetchCachedResponse } from './localStorage';
import { clearUser, getCredentials, refreshToken } from './rx/auth/user';
import { encryptData, loadBearerAuthHeaders, loadBasicAuthHeaders, rxApiURL, rxApiUsername, rxApiToken, rxApiSecret } from './utils';

interface RequestProps extends FetchProps {
  method: string;
}

interface FetchProps {
  params?: any;
  path?: string;
  headers?: any;
  url?: string;
  useJwtToken?: boolean;
  useUserToken?: boolean;
  useApiToken?: boolean;
  addApiToken?: boolean;
  cached?: boolean;
  cachedKey?: string;
  encodedParams?: Array<string>;
  retry?: number;
  skipToApiToken?: boolean;
  useApiServerToken?: boolean;
}

export const request: any = async ({
  params = {},
  path = '',
  method = 'GET',
  headers = {},
  url = rxApiURL ?? '',
  useJwtToken = false,
  useUserToken = false,
  useApiToken = false,
  addApiToken = false,
  cached = false,
  cachedKey,
  encodedParams = [],
  retry = 0,
  skipToApiToken = false,
  useApiServerToken = false,
}: RequestProps) => {
  try {
    //deep copy params
    let _params = JSON.parse(JSON.stringify(params));
    let _path = JSON.parse(JSON.stringify(path));
    let options: any = {};
    if (encodedParams?.length > 0) {
      for (const key of encodedParams) {
        if (_params[key]) {
          _params[key] = await encryptData(_params[key]);
        }
      }
      _params.encodedParams = encodedParams;
    }
    if (method === 'GET') {
      _path += (_path.includes('?') ? '&' : '?') + new URLSearchParams(_params).toString();
    } else {
      options.body = JSON.stringify(_params);
    }
    if (cached && method === 'GET') {
      const cachedResponse = await fetchCachedResponse(`${_path}:${method}${cachedKey ? cachedKey : ''}`);
      if (cachedResponse) {
        return cachedResponse;
      }
    }
    url += _path;
    let response;
    _params.origin = rxApiUsername;
    // _params.originId = rxApiID;
    let authheader = '';
    if (addApiToken) {
      authheader = `${headers.userId}:${rxApiToken}`;
    } else if (!headers?.Authorization) {
      if (useUserToken) {
        const user = await getCredentials();
        if (user?.token) {
          authheader = `${user.userId}:${user.token}`;
        } else {
          throw new Error('Invalid user');
        }
      } else if (useJwtToken) {
        const user = await getCredentials();
        if (user?.jwtTokens?.token) {
          authheader = `${user?.jwtTokens?.token}`;
          headers = { ...headers, ...loadBearerAuthHeaders(authheader) };
        } else if (skipToApiToken) {
          authheader = `${headers.userId}:${rxApiToken}`;
        } else {
          throw new Error('Invalid user');
        }
      } else if (useApiToken) {
        authheader = `${_params.email}:${rxApiToken}`;
      } else if (useApiServerToken) {
        authheader = `${rxApiUsername}:${process.env.RX_API_SERVER_TOKEN}`;
      } else {
        authheader = `${rxApiUsername}:${rxApiSecret}`;
      }
      if (!authheader) {
        throw new Error('Invalid user');
      }
    }
    // console.log('API.ts line 107 - authheader ', authheader);
    if (!headers?.Authorization) {
      headers = { ...headers, ...loadBasicAuthHeaders(authheader) };
    }
    // headers = { ...headers };
    // console.log('API.ts line 68 - headers ', headers);
    delete headers.userId;
    if (method === 'POST' || method === 'PUT') {
      headers['Content-Type'] = 'application/json';
    }
    if (typeof window === 'undefined') {
      headers['User-Agent'] = 'RugbyXplorer-RX-AUTH/1.0.0';
    }
    options.method = method;
    options.headers = headers;
    // console.log(`API.tsx line 21 - options `, options);
    // console.log('API.ts line 77 - url ', url);
    options.credentials = 'include';
    //options.mode = 'no-cors';
    // console.log(`API.ts line 116 - headers`, JSON.stringify(headers, null, 2));
    // console.log('API.ts line 84 - url ', url);
    // console.log('API.ts line 119 - options', JSON.stringify(options, null, 2));
    response = await fetch(url, options);
    // console.log('API.ts line 120 - response ', response);
    let jsonResponse: any = {};
    try {
      jsonResponse = await response.json();
    } catch (error) {
      console.log('API.tsx line 52 - error ', url, response, error);
    }
    // console.log('API.ts line 77 - headers ', jsonResponse, url, headers, response);
    // this only runs in the token is invalid then the API will throw a 498 code
    if (response.status === 498) {
      // console.log('file: API.ts:120 ~ retry, response.status:', retry, _path, path, response.status);
      if (retry === 0 && path !== 'rau/api/v1/auth/refreshToken/') {
        const success = await refreshToken();
        // console.log('API.ts line 122 - success ', success);
        if (success) {
          delete headers.Authorization;
          return request({ params, _path, method, headers, url, useJwtToken, useUserToken, useApiToken, cached, encodedParams, retry: 1 });
        } else {
          clearUser();
          return { error: { status: response.status, message: `Not Authorised [${response.statusText}]`, originalError: jsonResponse ?? response.statusText } };
        }
      } else {
        clearUser();
        return { error: { status: response.status, message: `Not Authorised [${response.statusText}]`, originalError: jsonResponse ?? response.statusText } };
      }
    } else if (![200, 203, 204].includes(response.status)) {
      if (response.status === 500) {
        return {
          error: {
            status: response.status,
            originalError: typeof response.statusText === 'object' ? JSON.parse(response.statusText) : response.statusText,
            message: jsonResponse?.message ?? jsonResponse?.status ?? 'Server Internal Error',
            details: typeof jsonResponse?.details === 'object' ? jsonResponse?.details : typeof jsonResponse?.details === 'string' ? JSON.parse(jsonResponse.details) : {},
          },
        };
      }
      // else if (response.status === 401) {
      //   await refreshToken();
      //   clearUser();
      //   return { error: { status: response.status, message: `Not Authorised [${response.statusText}]`, originalError: jsonResponse ?? response.statusText } };
      // }
      return { error: { status: response.status, message: 'Server Internal Error', originalError: jsonResponse ?? response.statusText } };
    }
    if (cached && method === 'GET') {
      let cacheExpiration = 3600;
      if (response.headers.get('cache-control')) {
        cacheExpiration = Number(response.headers.get('cache-control')?.match(/max.*age=(\d+)/)?.[1]);
      }
      await cacheResponse(`${_path}:${method}${cachedKey ? cachedKey : ''}`, jsonResponse, cacheExpiration);
    }
    return jsonResponse;
  } catch (error: any) {
    console.error('API.tsx line 181 - error ', url, error);
    let status = 520;
    let message = 'Unknown Error';
    if (error?.cause?.code === 'ECONNREFUSED') {
      status = 503;
      message = 'Server Communication Error';
    }
    return { error: { status, message, originalError: error } };
  }
};

export const get = async ({
  params,
  path,
  headers,
  url,
  useJwtToken,
  useUserToken,
  useApiToken,
  cached,
  cachedKey,
  encodedParams,
  skipToApiToken,
  useApiServerToken,
}: FetchProps) =>
  await request({ params, path, method: 'GET', headers, url, useJwtToken, useUserToken, useApiToken, cached, cachedKey, encodedParams, skipToApiToken, useApiServerToken });
export const post = async ({
  params,
  path,
  headers,
  url,
  useJwtToken,
  useUserToken,
  useApiToken,
  cached,
  cachedKey,
  encodedParams,
  skipToApiToken,
  useApiServerToken,
}: FetchProps) =>
  await request({ params, path, method: 'POST', headers, url, useJwtToken, useUserToken, useApiToken, cached, cachedKey, encodedParams, skipToApiToken, useApiServerToken });
export const put = async ({
  params,
  path,
  headers,
  url,
  useJwtToken,
  useUserToken,
  useApiToken,
  cached,
  cachedKey,
  encodedParams,
  skipToApiToken,
  useApiServerToken,
}: FetchProps) =>
  await request({ params, path, method: 'PUT', headers, url, useJwtToken, useUserToken, useApiToken, cached, cachedKey, encodedParams, skipToApiToken, useApiServerToken });
