import { SamlRequest } from '@rugby-au/saml-form';
import { get, post } from '../../API';
import { loadBasicAuthHeaders } from '../../utils';

/**
 * Verify MFA code
 * @param param0
 * @returns
 */
export const mfaVerify = async ({
  code,
  type,
  mfaType,
  token,
  clientId,
  codeChallenge,
  expirationInMinutes,
  samlRequest,
}: {
  code: string;
  type?: 'login' | 'mfa-type-verify' | string;
  mfaType?: 'sms' | 'email' | string;
  token: string;
  clientId: string | undefined;
  codeChallenge: string | undefined;
  expirationInMinutes: boolean | number;
  samlRequest?: SamlRequest | undefined;
}) => {
  try {
    const url = 'rau/api/v1/mfa-verify';
    const authheader = `${token}:${code}`;
    let headers = { ...loadBasicAuthHeaders(authheader), clientId, codeChallenge };
    return await post({ path: url, headers, params: { samlRequest, type, mfaType, ...(expirationInMinutes ? { expirationInMinutes } : {}) } });
  } catch (error) {
    throw error;
  }
};

/**
 * Request to resend MFA code
 */
export const mfaResend = async ({ token }: { token: string }) => {
  console.log('mfaAuth.ts line 38 - token ', token);
  try {
    const url = 'rau/api/v1/mfa-resend';
    const authheader = `Bearer ${token}`;
    return await get({ path: url, headers: { Authorization: authheader } });
  } catch (error) {
    throw error;
  }
};

//updateProfileMFA

//requestMobileVerify
/**
 * This method is used to request mobile verification or email verification according to type
 * @param param0 type: 'email' | 'mobile'
 * @returns
 */
export const requestContactMethodVerify = async ({ type, newMobile }: { type: string; newMobile: string }) => {
  try {
    const url = 'rau/api/v1/verify-mfa-type';
    return await post({ path: url, useUserToken: true, params: { type, mobile: newMobile } });
  } catch (error) {
    throw error;
  }
};
