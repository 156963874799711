// import { extractFieldString } from '@rugby-au/commons';
import { get } from '../API';
import { ipInfoToken } from '../utils';
// import { getUser } from './user';
// import { getCookie } from 'cookies-next';

// import { cached } from './utils';

// const defaultConfig: { [key: string]: any } = {
//   'en-AU': {
//     dateformat: 'dd/MM/yyyy',
//   },
//   'en-US': {
//     dateformat: 'dd/MM/yyyy',
//     // dateformat: 'MM/dd/yyyy',
//   },
// };

// export const getConfig = async (property: string, nationalId?: string) => {
//   console.log('config.ts line 19 - nationalId ', nationalId);
//   const user = await getUser();
//   let configValue = '';
//   // const cache = await cached({ wrapped: getNationals, key: 'nationalConfig' });
//   // const config = await cache();
//   const nationals = await getNationals();
//   const localeNational = getCookie('localeNational');
//   let _nationalId: string | null = null;

//   if (nationals && nationals.length > 0) {
//     if (nationalId) {
//       _nationalId = nationalId;
//     } else if (user?.national) {
//       _nationalId = user.national._id;
//     } else if (localeNational) {
//       _nationalId = localeNational;
//     } else {
//       _nationalId = nationals?.find((national: any) => national.config.general.language === navigator.language)._id;
//     }
//   }
//   if (_nationalId) {
//     const _national = nationals?.find((national: any) => national._id === _nationalId);
//     configValue = extractFieldString(_national, property, {});
//   }
//   if (!configValue) {
//     configValue = defaultConfig?.[navigator?.language ?? 'en-AU']?.[property] ?? defaultConfig['en-AU'][property];
//   }
//   return configValue;
// };

export const getNationals = async (nationalId?: string) => {
  let nationals = await get({
    path: 'rau/api/v2/nationals/',
    params: { nationalId },
    cached: true,
    cachedKey: `nationals-${nationalId}`,
  });
  nationals = Object.keys(nationals).map((key: string) => nationals[key]);
  // console.log('config.ts getNationals line 24 - nationals ', nationals);
  if (nationals && nationals.length > 0) {
    return nationals;
  } else {
    return undefined;
  }
};

/**
 * This method uses client IP info to get the national id
 * First calls Ipinfo service to get the country
 * Then calls the national-by-local service to get the national id
 * @returns {Promise<string>} national id or null
 */
export const getClientLocation = async () => {
  try {
    const result = await fetch(`https://ipinfo.io/json?token=${ipInfoToken}`, { method: 'GET' });
    console.log('config.ts line 74 - result ', result);
    const country = (await result.json()).country;

    if (!country) {
      throw new Error('No country found from IpInfo');
    }
    const nationalResponse = await get({ path: 'rau/api/v1/national-by-local', params: { local: country.toLowerCase() }, cached: true });
    return nationalResponse.nationalId;
  } catch (error) {
    console.error('Error in getClientLocation ~ error:', error);
    return null;
  }
};

/**
 * This method returns the national config for given keys for national ID
 * @param nationalId - national id
 */
export const getNationalConfig = async (nationalId: string, keys?: string[]) => {
  try {
    const config = await get({
      path: 'rau/api/v1/national-config',
      params: { national: nationalId, ...(keys ? { keys: keys.toString() } : {}) },
      cached: true,
      cachedKey: nationalId,
    });
    return config;
  } catch (error) {
    console.error('Error in getNationalConfig ~ error:', error);
    return null;
  }
};
