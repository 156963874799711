import React, { useCallback, useEffect, useMemo } from 'react';
import { View } from 'react-native';

import { Button, ButtonProps } from '@rugby-au/button';
import { Headings } from '@rugby-au/headings';
import {
  TextInputField,
  PhoneInputField,
  PickerInputField,
  CheckboxField,
  RadioButtonGroupField,
  DatePickerField,
  FieldTypes,
  RadioButtonGroupFieldProps,
  PickerInputFieldProps,
  AddressField,
  FieldRefProps,
  FieldRefMethodProps,
  LabelField,
} from '@rugby-au/form-fields';
import { Text } from '@rugby-au/commons';
import { FormConfigProps, FormFieldProps, FormRefMutableProps, FormResultProps } from './type';
import { useTheme } from '@rugby-au/theme';
import { useAppConfig } from '@rugby-au/app-config';

import { onUpdateAction } from './utils';
import { getFormStyles } from './styles';
import { request, useFetch } from '@rugby-au/services';

export const getInputField = ({ type, props, ref, onSubmit }: { type: FieldTypes; props: FormFieldProps; ref: FieldRefProps; onSubmit?: () => void }) => {
  switch (type) {
    case 'TextInput':
      return <TextInputField {...props} fieldRef={ref} onSubmit={onSubmit} />;
    case 'PhoneInput':
      return <PhoneInputField {...props} fieldRef={ref} />;
    case 'PickerInput':
      return <PickerInputField {...(props as PickerInputFieldProps)} fieldRef={ref} />;
    case 'Checkbox':
      return <CheckboxField {...props} fieldRef={ref} />;
    case 'RadioButtonGroup':
      return <RadioButtonGroupField {...(props as RadioButtonGroupFieldProps)} fieldRef={ref} />;
    case 'DateInput':
      return <DatePickerField {...props} fieldRef={ref} />;
    case 'AddressInput':
      return <AddressField {...props} fieldRef={ref} />;
    case 'Button':
      return <Button {...(props as ButtonProps)} />;
    case 'LabelField':
      return <LabelField {...props}> </LabelField>;
    default:
      return <Text>Field Not Found {type}</Text>;
  }
};

export const Form = ({
  formRef,
  formConfig,
  onFinished,
  extraValidation,
  modifySubmitPath,
  getHeaders,
  cleanSubmitData,
  showOnSubmitError = false,
}: {
  formRef?: FormRefMutableProps;
  formConfig: FormConfigProps;
  onFinished?: (response: any) => Promise<{ message: string; isValid: boolean }>;
  extraValidation?: (submitData: any) => Promise<{ message: string; isValid: boolean }>;
  modifySubmitPath?: (submithPath: string) => string;
  getHeaders?: (submitData: { [key: string]: any }) => Promise<{ [key: string]: any }>;
  cleanSubmitData?: (submitData: any, nationalId: any) => Promise<any>;
  showOnSubmitError?: boolean;
}) => {
  //get theme values
  const { colors, spacing } = useTheme();
  const [_formConfig, setFormConfig] = React.useState<FormConfigProps>(formConfig);
  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setisLoading] = React.useState<boolean>(false);
  const { fetchRequest } = useFetch({});

  // create Refs for all the fields
  const fieldRefs = useMemo(
    () => _formConfig.fields.map((field, index) => ({ key: field.key ?? `${index}`, ref: React.createRef<FieldRefMethodProps>(), hidden: field.hidden ?? false })),
    [_formConfig.fields],
  );

  //Update Form Config state when ever the props get updated
  useEffect(() => {
    setFormConfig(formConfig);
  }, [formConfig]);

  const appConfig = useAppConfig();

  const onSubmit = useCallback(async (): Promise<{ isValid: boolean; response: any; submitData: any }> => {
    if (_formConfig.meta?.submitButton) {
      setisLoading(true);
    }

    let isValid = true;
    for await (let fieldRef of fieldRefs) {
      if (
        fieldRef.ref.current &&
        !fieldRef.hidden &&
        (!appConfig.nationalId ||
          (appConfig.nationalId && !_formConfig.nationalConfig) ||
          (appConfig.nationalId && _formConfig.nationalConfig && !_formConfig?.nationalConfig?.[appConfig.nationalId]?.skip?.includes(fieldRef.key)))
      ) {
        const isFieldValid = await fieldRef.ref.current.validate();
        isValid = isValid && isFieldValid;
      }
    }
    const result: FormResultProps = { isValid, response: null, submitData: null };
    if (result.isValid) {
      // submit the form
      let submitData = fieldRefs
        .filter(field => !field.hidden)
        .reduce((acc, fieldRef) => {
          if (fieldRef.ref.current) {
            let value;
            if (typeof fieldRef.ref.current.value === 'function') {
              value = fieldRef.ref.current.value();
            } else {
              value = fieldRef.ref.current.value;
            }
            return { ...acc, [fieldRef.key]: value };
          }
          return acc;
        }, {});
      if (extraValidation) {
        const extraValidationResult = await extraValidation(submitData);
        if (!extraValidationResult.isValid) {
          result.response = { error: extraValidationResult.message };
          result.isValid = isValid && extraValidationResult?.isValid;
        }
      }
      //It is importante the cleanSubmitData is called after the extraValidation. For instance, if the extraValidation is used to validate password and confimr password cleanSubmitData will encode the password first.
      if (cleanSubmitData) {
        submitData = await cleanSubmitData(submitData, appConfig?.nationalId);
      }
      let headers;
      if (getHeaders) {
        try {
          headers = await getHeaders(submitData);
        } catch (e: any) {
          if (_formConfig.meta?.submitMethod && !onFinished) {
            setError(e.message);
          }
          result.response = { error: e.message };
          result.isValid = false;
          if (_formConfig.meta?.submitButton) {
            setisLoading(false);
          }
          return result;
        }
      }
      result.submitData = submitData;

      if (result.isValid) {
        if (_formConfig.meta?.submitMethod) {
          try {
            let submitPath = _formConfig.meta.submitPath;
            if (submitPath && modifySubmitPath) {
              submitPath = modifySubmitPath(submitPath);
            }
            result.response = await fetchRequest(request, {
              params: submitData,
              path: submitPath,
              method: _formConfig.meta.submitMethod,
              headers,
              ..._formConfig.meta.requestParams,
            });
            if (result.response.error) {
              result.isValid = false;
              if (showOnSubmitError) {
                setError(result?.response?.error?.originalError?.status || result?.response?.error?.message);
              }
            }
          } catch (e: any) {
            //set global error and show to the user
            console.error('Form.tsx line 75 - e ', e);
            result.isValid = false;
          }
        }
      }
      if (onFinished) {
        const onFinishedRes = await onFinished(result);
        //If onFinished returns false, the form will be invalid
        if (onFinishedRes && !onFinishedRes.isValid) {
          result.isValid = false;
        }
      }
    }
    if (_formConfig.meta?.submitButton) {
      setisLoading(false);
    }
    return result;
  }, [
    _formConfig.meta?.submitButton,
    _formConfig.meta?.submitMethod,
    _formConfig.meta?.submitPath,
    _formConfig.meta?.requestParams,
    _formConfig.nationalConfig,
    fieldRefs,
    appConfig.nationalId,
    cleanSubmitData,
    getHeaders,
    extraValidation,
    onFinished,
    modifySubmitPath,
    showOnSubmitError,
    fetchRequest,
  ]);

  useEffect(() => {
    if (formRef) {
      formRef.current = {
        onSubmit,
        setValues: (fields: FormFieldProps[]) => {
          setFormConfig(prevFormConfig => ({
            ...prevFormConfig,
            fields: prevFormConfig.fields.map(field => ({ ...field, ...fields.find(value => value.key === field.key) })),
          }));
        },
      };
    }
  }, [formRef, onSubmit]);

  //Styles
  const { ids, styles } = getFormStyles({ colors, spacing });

  // console.log('Form.tsx line 239 - _formConfig ', _formConfig);
  return (
    <View style={styles.container}>
      {_formConfig?.meta?.title && (
        <View style={styles.headingContainer}>
          <Headings title={_formConfig?.meta?.title} level={'2'} />
        </View>
      )}
      {/* Render Form Field using the form config Field list */}
      <View style={styles.formContainer}>
        {_formConfig.fields
          .filter(field => !field.hidden)
          .map((field, index) => {
            // Add Props to field
            const _field = {
              ...field,
              ...(field.onUpdateAction ? { onChangeField: (value: any) => onUpdateAction({ action: field.onUpdateAction, value, appConfig }) } : {}),
            };
            // console.log('Form.tsx line 244 - _field ', _field);
            // Checks whether there is a nationalId config and if so, whether the condition is satisfied

            if (!field.displayValidation || field.key?.includes(appConfig.nationalId as string)) {
              return (
                <View
                  dataSet={{ media: ids.fieldContainer }}
                  style={{
                    ...styles.fieldContainer,
                    zIndex: field.displayType === 'DateInput' ? 5 : 0,
                    ...field.styles,
                  }}
                  key={field.key}>
                  {getInputField({ type: field.displayType, props: _field, ref: fieldRefs[index].ref, onSubmit: onSubmit })}
                </View>
              );
            } else {
              return null;
            }
          })}
      </View>
      {_formConfig.meta?.submitButton && (
        <>
          {error && <Text style={styles.error}>{error}</Text>}
          <View style={styles.buttonContainer}>
            <Button
              {..._formConfig.meta?.submitButton}
              title={_formConfig.meta?.submitButton.title ?? 'Submit'}
              onPress={() => {
                if (!isLoading) {
                  onSubmit();
                }
              }}
              isLoading={isLoading}
            />
          </View>
        </>
      )}
    </View>
  );
};
